import api from '../config/Api';

const endpoint = 'v2/marketing/poll';


// eslint-disable-next-line import/no-anonymous-default-export
export default {

  saveSurvey( data ) {
    return api.post( endpoint, data );
  }


};
