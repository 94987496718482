import React from 'react';
import ReactDOM from 'react-dom';
import App from './routes/App';
import {IntlProvider} from "react-intl";
import reportWebVitals from './reportWebVitals';

import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";

const messages = {
    'es': messages_es,
    'en': messages_en
};
const language = navigator.language.split(/[-_]/)[0];
console.log('IDIOMA', language);
ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={messages[language]}>
      <App />
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
