import { create } from 'apisauce';
import _ from 'lodash';

const api = create( {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
} );

api.addRequestTransform( ( request ) => {
  request.url = `${process.env.REACT_APP_API_URL}${request.url}`;

  const data = JSON.parse( localStorage.getItem( 'mk211' ) );
  const accessToken = _.get( data, 'user.access_token' );
  if ( accessToken ) request.headers.Authorization = `Bearer ${accessToken}`;
  if ( _.get( data, 'user.activeSession.id' ) ) {
    request.headers['x-session'] = data.user.activeSession.id;
  }
} );

api.addResponseTransform( ( response ) => {
  const { data, ok } = response;

  if ( !ok ) {
 

    let errors = '';

    if ( !data ) return 'API ERROR';
    const config = JSON.parse( _.get( response, 'config.data', '{}' ) );
    if ( data.error === 'invalid_grant' && ( !config || !config.grant_type ) ) {
      window.location.replace( '/login' );
    }

    if ( data ) {
      if ( data.error_description ) errors = data.error_description;
      else if ( data.errors ) {
        if ( _.isObject( data.errors ) && !_.isArray( data.errors ) ) {
          errors = [];
          _.map( data.errors, ( error ) => {
            if ( _.isString( error ) ) errors.push( error );
            else if ( _.isArray( errors ) ) errors.push( error.join( '\n' ) );
          } );
          errors = errors.join( '\n' );
        } else {
          errors = data.errors; // eslint-disable-line
        }
      } else if ( data.error ) {
        if ( _.isObject( data.error ) && !_.isArray( data.error ) ) {
          errors = [];
          _.map( data.error, ( error ) => {
            if ( _.isString( error ) ) errors.push( error );
            else if ( _.isArray( errors ) ) errors.push( error.join( '\n' ) );
          } );
          errors = errors.join( '\n' );
        } else {
          errors = data.error;
        }
      } else if ( data.message ) errors = data.message;
      else errors = data;
    } else {
      errors = 'API ERROR';
    }

    response.errors = errors;
  }
} );

export default api;
