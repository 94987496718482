import React from 'react';
import {FormattedMessage} from 'react-intl';

// import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/survey.scss'
class Survey extends React.Component {
 
    render() {
        return (
            <div className="container">
              <div class="alert alert-success" role="alert">
                <h4 class="alert-heading">Datos almacenados con éxito!</h4>
                <p>El resultado del diagnóstico será enviado  al correo electronico suministrado en la encuesta.</p>
                <hr/>
                <p class="mb-0">
                Si desea recibir comentarios y sugerencias o mantener una entrevista virtual para discutir sobre estrategias para mejorar su desempeño en estas dimensiones y la medición del impacto sobre sus resultados, 
                simpemente envíenos un mail ahora a: info@marketingtech.biz   Con gusto le brindaremos la información que necesite para incrementar los resultados que obtiene de su cartera de clientes.
                </p>
              </div>
              {this.props.locale} dd
              <FormattedMessage id="app.learn-react-link"
                      defaultMessage="Learn React"
                      description="Link on react page"/>
            </div>
          );
    }
}
export default ( Survey );
