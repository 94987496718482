import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { FormHelperText } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import { injectIntl } from 'react-intl';

import SurveyServices from "../services/Survey";
// import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/survey.scss';
import '../styles/style.css';
function am4themes_mk21BigSur2( target ) {
  if ( target instanceof am4core.ColorSet ) {
    // eslint-disable-next-line no-param-reassign
    target.list = [
  
      am4core.color( '#7fb000' ),
      am4core.color( '#7e2ab9' ),
      am4core.color( '#1998f6' ),
      am4core.color( '#1ec998' ).lighten( 0.3 ),
    ];
  }
}
am4core.useTheme( am4themes_mk21BigSur2 );

const required = value => (value ? undefined : 'fieldError.required')
const email = val => ( val ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( val ) ? null : 'fieldError.email' : null );
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

class Survey extends React.Component {
    constructor() {
        super();
        this.state = { isLoading: false, showResults: false };
        this.generateGraph = this.generateGraph.bind(this);
    }
    submitForm = async ( data ) => {
      this.setState({isLoading: true});

      const dataToSend = {
        relationship : [],
        retention: [],
        profitability: [],
        referencing: [],
        recovery: [],
        reactivation: [],
        email: data.email,
        name: data.name,
        companyName: data.companyName,
        telephone: data.telephone,
        isoLang: 'es',
        
        createdAtCli: moment().format('YYYY-DD-MM HH:mm:ss'), //"2021-01-01 13:00:00"
      }

      for (let i = 1; i < 8; i++) {
        if ( _.indexOf(data.rel, `${i}`) >= 0 ) {
          dataToSend.relationship[i-1] = 1;
        } else {
          dataToSend.relationship[i-1] = 0;
        }
      }
      for (let i = 1; i < 8; i++) {
        if ( _.indexOf(data.ret, `${i}`) >= 0 ) {
          dataToSend.retention[i-1] = 1;
        } else {
          dataToSend.retention[i-1] = 0;
        }
      }
      for (let i = 1; i < 7; i++) {
        if ( _.indexOf(data.ren, `${i}`) >= 0 ) {
          dataToSend.profitability[i-1] = 1;
        } else {
          dataToSend.profitability[i-1] = 0;
        }
      }
      for (let i = 1; i < 4; i++) {
        if ( _.indexOf(data.ref, `${i}`) >= 0 ) {
          dataToSend.referencing[i-1] = 1;
        } else {
          dataToSend.referencing[i-1] = 0;
        }
      }
      for (let i = 1; i < 6; i++) {
        if ( _.indexOf(data.rec, `${i}`) >= 0 ) {
          dataToSend.recovery[i-1] = 1;
        } else {
          dataToSend.recovery[i-1] = 0;
        }
      }
      for (let i = 1; i < 5; i++) {
        if ( _.indexOf(data.rea, `${i}`) >= 0 ) {
          dataToSend.reactivation[i-1] = 1;
        } else {
          dataToSend.reactivation[i-1] = 0;
        }
      }

      const promise = SurveyServices.saveSurvey( dataToSend );
      await promise.then( ( response ) => {
        this.setState({isLoading: false});
        if ( !response.ok ) {
          try {
            console.log('Error 1');
          } catch ( err ) {
              console.log( 'Please Contact the Admin ( Code.500 )' );
          }
        } else {
          // console.log(response);
          this.setState({showResults: true, data: response.data.totals})
          this.generateGraph( response.data.totals );
          // this.props.history.push("/success");
        }
        //this.setState({isLoading: false});
        
      } );
      //setTimeout(() => {  this.props.history.push("/success");console.log("World!");this.setState({isLoading: false}); }, 5000);
        

    }

    generateGraph = ( data1 ) =>
    {
      const { intl } = this.props;
      console.log(data1);
      let chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.paddingRight = 20;
      
      let data = [
        {
          "country": intl.formatMessage( { id: 'general.title' } ),
          "visits": data1.generalTotal
        }, {
          "country": intl.formatMessage( { id: 'rel.title' } ),
          "visits": data1.relationshipTotal
        }, {
          "country": intl.formatMessage( { id: 'ret.title' } ),
          "visits": data1.retetionTotal
        }, {
          "country": intl.formatMessage( { id: 'ren.title' } ),
          "visits": data1.profitabilityTotal
        }, {
          "country": intl.formatMessage( { id: 'ref.title' } ),
          "visits":  data1.referencingTotal
        }, {
          "country": intl.formatMessage( { id: 'rec.title' } ),
          "visits": data1.recoveryTotal
        }, {
          "country": intl.formatMessage( { id: 'rea.title' } ),
          "visits": data1.reactivationTotal
        }
      ];


      chart.data = data;
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "country";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      
      categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
        // eslint-disable-next-line no-self-compare
        if (target.dataItem && target.dataItem.index & 2 == 2) {
          return dy + 25;
        }
        return dy;
      });
      
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.max = 100;
      var range = valueAxis.axisRanges.create();
      range.value = 35;
      range.endValue = 70;
      range.axisFill.fill = am4core.color("#7fb000");
      range.axisFill.fillOpacity = 0.2;
      range.grid.strokeOpacity = 0;
      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "visits";
      series.dataFields.categoryX = "country";
      series.name = "Visits";
      series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = .8;
      
      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;


      this.chart = chart;
    }

    render() {
        const { isLoading, showResults, data } = this.state;
        const { intl } = this.props;
        return (
            <div className="container">
              {isLoading
                && (
                    <div className="app-loading">
                        <CircularProgress size={50} thickness={5} />
                    </div>
              )}
              <span class="title-large letter-spacing-1 font-weight-500 black-text"><FormattedMessage id="welcome.title" /></span>
              <h5><FormattedMessage id="welcome.subtitle" /></h5>

              <div class="separator-line-thick bg-green no-margin-lr"></div>
              {!showResults && (
                <p>
                  <FormattedMessage id="welcome.message" />
                </p>
              )}
              {showResults && (
                <>
                <div className="row">
                  <div className="col-12">
                    <p>
                      <h4 class="alert-heading"><FormattedMessage id="success.header" /></h4>
                      <p><FormattedMessage id="success.message1" /></p>
                    </p>
                  </div>
                </div>
                <div className="row flex">
                  <div className="col-10 flex">
                    <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
                  </div>
                  <div className="col-2">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">SCORE</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        <tr>
                          <th><FormattedMessage id="general.title" /></th>
                          <td> {data.generalTotal} </td>
                        </tr>
                        <tr>
                          <th><FormattedMessage id="rel.title" /></th>
                          <td> {data.relationshipTotal} </td>
                        </tr>
                        <tr>
                          <th><FormattedMessage id="ret.title" /></th>
                          <td>{data.retetionTotal}</td>
                        </tr>
                        <tr>
                          <th><FormattedMessage id="ren.title" /></th>
                          <td>{data.profitabilityTotal}</td>
                        </tr>
                        <tr>
                          <th><FormattedMessage id="ref.title" /></th>
                          <td>{data.referencingTotal}</td>
                        </tr>
                        <tr>
                          <th><FormattedMessage id="rec.title" /></th>
                          <td>{data.recoveryTotal}</td>
                        </tr>
                        <tr>
                          <th><FormattedMessage id="rea.title" /></th>
                          <td>{data.reactivationTotal}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                </>

              )}
              {!showResults && (
                <div className="row">
                  <div className="col">
                    <Form
                      onSubmit={this.submitForm}
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                          
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"><FormattedMessage id="rel.title" /></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                  />{' '}
          
                                </td>
                                <td>
                                  <FormattedMessage id="rel.p1" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="2"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="rel.p2" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="3"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="rel.p3" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="4"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="rel.p4" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="5"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="rel.p5" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">6</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="6"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="rel.p6" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">7</th>
                                <td>
                                  <Field
                                    name="rel"
                                    component="input"
                                    type="checkbox"
                                    value="7"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rel.p7" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"><FormattedMessage id="ret.title" /></th>
                              </tr>
                              <tr>
                                <th scope="row">1</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p1" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="2"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p2" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="3"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p3" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="4"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p4" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="5"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p5" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">6</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="6"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p6" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">7</th>
                                <td>
                                  <Field
                                    name="ret"
                                    component="input"
                                    type="checkbox"
                                    value="7"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="ret.p7" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"><FormattedMessage id="ren.title" /></th>
                              </tr>
                              <tr>
                                <th scope="row">1</th>
                                <td>
                                  <Field
                                    name="ren"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ren.p1" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>
                                  <Field
                                    name="ren"
                                    component="input"
                                    type="checkbox"
                                    value="2"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ren.p2" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>
                                  <Field
                                    name="ren"
                                    component="input"
                                    type="checkbox"
                                    value="3"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ren.p3" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>
                                  <Field
                                    name="ren"
                                    component="input"
                                    type="checkbox"
                                    value="4"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ren.p4" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td>
                                  <Field
                                    name="ren"
                                    component="input"
                                    type="checkbox"
                                    value="5"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ren.p5" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">6</th>
                                <td>
                                  <Field
                                    name="ren"
                                    component="input"
                                    type="checkbox"
                                    value="6"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ren.p6" /> 
                                </td>
                              </tr>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"><FormattedMessage id="ref.title" /></th>
                              </tr>
                              <tr>
                                <th scope="row">1</th>
                                <td>
                                  <Field
                                    name="ref"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ref.p1" />

                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>
                                  <Field
                                    name="ref"
                                    component="input"
                                    type="checkbox"
                                    value="2"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ref.p2" />

                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>
                                  <Field
                                    name="ref"
                                    component="input"
                                    type="checkbox"
                                    value="3"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="ref.p3" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"><FormattedMessage id="rec.title" /></th>
                              </tr>
                              <tr>
                                <th scope="row">1</th>
                                <td>
                                  <Field
                                    name="rec"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rec.p1" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>
                                  <Field
                                    name="rec"
                                    component="input"
                                    type="checkbox"
                                    value="2"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rec.p2" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>
                                  <Field
                                    name="rec"
                                    component="input"
                                    type="checkbox"
                                    value="3"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rec.p3" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>
                                  <Field
                                    name="rec"
                                    component="input"
                                    type="checkbox"
                                    value="4"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rec.p4" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">5</th>
                                <td>
                                  <Field
                                    name="rec"
                                    component="input"
                                    type="checkbox"
                                    value="5"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rec.p5" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"><FormattedMessage id="rea.title" /></th>
                              </tr>
                              <tr>
                                <th scope="row">1</th>
                                <td>
                                  <Field
                                    name="rea"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                  />{' '}
                                </td>
                                <td>
                                  <FormattedMessage id="rea.p1" />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>
                                  <Field
                                    name="rea"
                                    component="input"
                                    type="checkbox"
                                    value="2"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rea.p2" /> 
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td>
                                  <Field
                                    name="rea"
                                    component="input"
                                    type="checkbox"
                                    value="3"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rea.p3" /> 
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">4</th>
                                <td>
                                  <Field
                                    name="rea"
                                    component="input"
                                    type="checkbox"
                                    value="4"
                                  />{' '}
                                </td>
                                <td>
                                <FormattedMessage id="rea.p4" />
                                </td>
                              </tr>
                              
                              <tr>

                                <td colSpan={3}>
                                  <div class="mb-3">
                                    <Field
                                      name="name"
                                      component="input"
                                      type="text"
                                      validate={required}
                                    >
                                      {({ input, meta }) => (
                                          <div>
                                            <input {...input} className="form-control" type="text" placeholder={intl.formatMessage( { id: 'customerName' } )} />
                                            {meta.error && meta.touched && 
                                            <FormHelperText>
                                            {intl.formatMessage( { id: meta.error } )}
                                            </FormHelperText>
                                            }
                                          </div>
                                        )}
                                    </Field>
                                  </div>
                                  <div class="mb-3">
                                  
                                    <Field
                                      name="email"
                                      component="input"
                                      type="email"
                                      validate={composeValidators(required, email)}
                                    >
                                      {({ input, meta }) => (
                                          <div>
                                            <input {...input} className="form-control" type="text" placeholder={intl.formatMessage( { id: 'customerEmail' } )} />
                                            {meta.error && meta.touched && 
                                            <FormHelperText>
                                            {intl.formatMessage( { id: meta.error } )}
                                            </FormHelperText>
                                            }
                                          </div>
                                        )}
                                    </Field>       
                                  </div>
                                  <div class="mb-3">
                                    <Field
                                      name="companyName"
                                      component="input"
                                      type="text"
                                      validate={required}
                                    >
                                    {({ input, meta }) => (
                                        <div>
                                          <input {...input} className="form-control" type="text" placeholder={intl.formatMessage( { id: 'customerCompany' } )} />
                                          {meta.error && meta.touched && 
                                          <FormHelperText>
                                          {intl.formatMessage( { id: meta.error } )}
                                          </FormHelperText>
                                          }
                                        </div>
                                      )}
                                  </Field>
                                  </div>
                                  <div class="mb-3">
                                    <Field
                                    name="telephone"
                                    component="input"
                                    type="text"
                                  >
                                    {({ input, meta }) => (
                                        <div>
                                          <input {...input} className="form-control" type="text" placeholder={intl.formatMessage( { id: 'customerPhone' } )} />
                                          {meta.error && meta.touched && 
                                          <FormHelperText>
                                          {intl.formatMessage( { id: meta.error } )}
                                          </FormHelperText>
                                          }
                                        </div>
                                      )}
                                    </Field>  
                                  </div>                          
                                </td>
                              </tr>

                              
                              <tr>
                                <td ></td>
                                <td>

                                </td>
                                <td>
                                  <div className="d-grid d-md-flex justify-content-md-end">
                    							  <input class="btn inner-link btn-black btn-small button xs-margin-bottom-five" type="submit" value={intl.formatMessage( { id: 'button.save' } )} />
                                  </div>                             
                                </td>
                              </tr>
                            </tbody>
                          </table>

                        </form>
                      )}
                    />
                  </div>
                </div>
              )}

            </div>
          );
    }
}
export default withRouter( injectIntl( Survey ) );
