import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Survey from './Survey';
import Success from './Success';


function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/">
            <Survey />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
